import dispatchModule from "./dispatch";
import DeliveryNotesModule from "./delivery-notes";
import HistoryModule from "./history";
import RMUploadModule from "./rm-upload";

export const dispatchModules = {
  dispatch: dispatchModule,
  deliveryNotes: DeliveryNotesModule,
  hsitory: HistoryModule,
  RMUpload: RMUploadModule,
};
