const BlendType = () => import("./views/blend_type.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/blend_type",
      component: BlendType,
      name: "BlendType",
      meta: {
        groupName: "Blend",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
