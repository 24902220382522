const PoGeneration = () => import("./views/po-generation.vue");
const PoClose = () => import("./views/po-closing.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/po_generation",
      component: PoGeneration,
      name: "Po Generation",
      meta: {
        groupName: "Private Sale",
      },
      // path: "/po-close",
      // component: PoClose,
      // name: "Po Close",
      // meta: {
      //   groupName: "Private",
      // },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
