<template>
  <v-app-bar
    absolute
    :color="background"
    height="auto"
    class="py-4 px-2 toolbar-content-padding-y-none"
    flat
  >
    <v-container>
      <v-row>
        <v-col cols="3" class="d-flex align-center">
          <p class="font-weight-bolder ms-lg-0 ms-4 mb-0 text-white text-sm">
            .
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
import AppBarAuthentication from "./Widgets/AppBarAuthentication.vue";
import AppBarApplications from "./Widgets/AppBarApplications.vue";

export default {
  name: "app-bar",
  components: {
    AppBarAuthentication,
    AppBarApplications,
  },
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
  },
};
</script>
