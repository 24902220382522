const ProvisionalPlans = () => import("./views/provisional-plans.vue");
const ProvisionalPlanDetails = () => import("./views/provisional-plans-details.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/provisional_plan",
      component: ProvisionalPlans,
      name: "ProvisionalPlans",
      meta: {
        groupName: "Blend",
      },
    },
    {
      path: "/provisionalPlanDetails",
      component: ProvisionalPlanDetails,
      name: "Provisional Plan Detail",
      meta: {
        groupName: "Blend",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
