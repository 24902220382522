const Role = () => import("./views/Role.vue");
const RolePermission = () => import("./views/RolePermission.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/role",
      component: Role,
      name: "Role",
      meta: {
        groupName: "Master",
      },
    },
    {
      path: "/role_permission",
      component: RolePermission,
      name: "Role Permission",
      meta: {
        groupName: "Master",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
