const Category = () => import("./views/Category");
const CategoryBulk = () => import("./views/CategoryBulk.vue");

import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/category",
      component: Category,
      name: "Category",
      meta: {
        groupName: "Master",
      },
    },
    {
      path: "/category_bulk",
      component: CategoryBulk,
      name: "Category Bulk",
      name: "Category Bulk",
      meta: {
        groupName: "Master",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
