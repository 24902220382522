const Grade = () => import("./views/Grade.vue");
const GradeBulk = () => import("./views/GradeBulk.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/grade",
      component: Grade,
      name: "Grade",
      meta: {
        groupName: "Master",
      },
    },
    {
      path: "/grade_bulk",
      component: GradeBulk,
      name: "Grade Bulk",
      meta: {
        groupName: "Master",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
