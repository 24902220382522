const CreateIrmc = () => import("./views/create-irmc.vue");
const PreCreateIrmc = () => import("./views/pre-create-irmc.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/create_irmc",
      component: CreateIrmc,
      name: "CreateIrmc",
      meta: {
        groupName: "IRMC",
      },
    },
    {
      path: "/pre_create_irmc",
      component: PreCreateIrmc,
      name: "PreCreateIrmc",
      meta: {
        groupName: "IRMC",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
