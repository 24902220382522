const Dispatch = () => import("./views/Dispatch.vue");

const RMArrival = () => import("./views/rm-arrival.vue");
const RMUpdate = () => import("./views/rm-update.vue");

import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/dispatch",
      component: Dispatch,
      name: "Delivery Order",
      meta: {
        groupName: "Delivery Order",
      },
    },
    {
      path: "/rm_arrival",
      component: RMArrival,
      name: "RM Arrival",
      meta: {
        groupName: "Delivery Order",
      },
    },
    {
      path: "/rm_update",
      component: RMUpdate,
      name: "RM Update",
      meta: {
        groupName: "Delivery Order",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
