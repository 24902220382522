const PurchaseReport = () => import("./views/PurchaseReport.vue");
const ItemWiseReport = () => import("./views/Item-wise.vue");
const CatalogueReference = () => import("./views/view-catalogue.vue");
const SeasonWise = () => import("./views/season-wise.vue");

import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/purchase_report",
      component: PurchaseReport,
      name: "Purchase Report",
      meta: {
        groupName: "Reports",
      },
    },
    {
      path: "/itemwise_report",
      component: ItemWiseReport,
      name: "Item-wise Report",
      meta: {
        groupName: "Reports",
      },
    },
    ,
    {
      path: "/view_catalogue",
      component: CatalogueReference,
      name: "View Catalogue",
      meta: {
        groupName: "Auction",
      },
    },
    {
      path: "/season_wise",
      component: SeasonWise,
      name: "Season Wise",
      meta: {
        groupName: "Reports",
      },
    },
    ,
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
