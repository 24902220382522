const PurchaseRequest = () => import("./views/purchase-request.vue");
const BlendRatio = () => import("./views/blend-ratio.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/purchase_request",
      component: PurchaseRequest,
      name: "PurchaseRequest",
      meta: {
        groupName: "Purchase Request",
      },
    },
    {
      path: "/blend_ratio",
      component: BlendRatio,
      name: "BlendRatio",
      meta: {
        groupName: "Purchase Request",
      },
    },
    ,
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
