import auctionCatalogueModule from "./Catalogue";
import auctionAllocationModule from "./Allocation";
import auctionContractModule from "./contract-note-upload";
import auctionMyAllocationModule from "./my-allocation";
import auctionKnockdownModule from "./Knockdown";
import auctionMyCommentsModule from "./my-comments";
import allocationListModule from "./Allocation-List";
import auctionHistoryModule from "./auction-history";

export const auctionModules = {
  auctionCatalogue: auctionCatalogueModule,
  auctionAllocation: auctionAllocationModule,
  auctionContract: auctionContractModule,
  auctionMyAllocation: auctionMyAllocationModule,
  auctionKnockdown: auctionKnockdownModule,
  auctionMyComments: auctionMyCommentsModule,
  allocationList: allocationListModule,
  auctionHistory: auctionHistoryModule,
};
