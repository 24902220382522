const Knockdown = () => import("./views/Knockdown.vue");
const ListKnockdown = () => import("./views/ListKnockdown.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/auction_knockdown",
      component: Knockdown,
      name: "Knockdown",
      meta: {
        groupName: "Auction",
      },
    },
    {
      path: "/auction_knockdown_list",
      component: ListKnockdown,
      name: "Knockdown List",
      meta: {
        groupName: "Auction",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
