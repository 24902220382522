<template>
  <v-app>
    <v-main
      class="auth-pages"
      :style="`background-image:  url(${require('../../assets/img/curved-images/BG_Footer2.png')};  background-position: bottom right;background-size:20% 13%;`"
    >
      <div
        class="header-auth position-relative ma-1 pb-16 pt-16 border-radius-xl"
        :style="`background-image:  url(${require('../../assets/img/curved-images/BG1.png')}; background-size: cover; background-position: 50%;`"
      >
        <span
          class="mask bg-gradient-default border-radius-xl opacity-0"
        ></span>
        <v-container>
          <v-row class="d-flex mt-2">
            <v-col cols="12" md="4" class="mx-auto py-0 position-relative">
              <h6
                class="text-h6 font-weight-bold text-white text-center mb-2"
                v-if="this.$route.name == 'Pricing'"
              >
                {{ headerTitle() }}
              </h6>
              <h6
                class="text-h4 font-weight-medium text-center mb-2 mt-5"
                style="color: #caf1b7"
                v-else
              >
                {{ headerTitle() }}
              </h6>
              <p
                style="color: #9be59d"
                class="font-size-root text-center font-weight-medium mb-12"
              >
                <!-- style="color: #009d52" -->

                {{ paragraphs }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <app-bar-auth background="transparent" has-bg linkColor="white">
      </app-bar-auth>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <v-container class="mt-n16 pb-0">
          <router-view></router-view>
          <content-footer auth v-if="!$route.meta.hideFooter"></content-footer>
        </v-container>
      </fade-transition>
    </v-main>
  </v-app>
</template>
<script>
import AppBarAuth from "@/components/AppBarAuth";
// import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    AppBarAuth,
    // FadeTransition,
    ContentFooter,
  },
  data() {
    return {
      paragraphs: "",
      tab: null,
    };
  },
  methods: {
    headerTitle() {
      switch (this.$route.name) {
        case "SignUpBasic":
          this.paragraphs = ".";
          return ".";
        default:
          break;
      }
    },
  },
};
</script>
