const PoConfirmation = () => import("./views/POConfirmation.vue");
const PoConfirmationDetails = () => import("./views/POConfirmationDetail.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/po_confirmation",
      component: PoConfirmation,
      name: "PO Confirmation",
      meta: {
        groupName: "Private Sale",
      },
    },
    {
      path: "/po_confirmation_details",
      component: PoConfirmationDetails,
      name: "PO Confirmation Details",
      meta: {
        groupName: "Private Sale",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
