const InternalTransferModule = () => import("./views/internal-transfer.vue");
const InternalTransferDeliveryNotesModule = () =>
  import("./views/delivery-notes.vue");
const viewDraft = () => import("./views/view-draft.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/internal_transfer",
      component: InternalTransferModule,
      name: "Internal Transfer",
      meta: {
        groupName: "Transfer",
      },
    },
    {
      path: "/internal_delivery_notes",
      component: InternalTransferDeliveryNotesModule,
      name: "Internal Transfer Delivery Note",
      meta: {
        groupName: "Transfer",
      },
    },
    {
      path: "/view_draft",
      component: viewDraft,
      name: "view Draft",
      meta: {
        groupName: "Transfer",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
