import districtModule from "./district";
import stateModule from "./State";
import gradeModule from "./Grade";

import auctionCenterModule from "./AuctionCenter";
import brokerModule from "./Broker";
import competitorModule from "./Competitor";
import seasonModule from "./Season";

import masterStateModule from "./MasterState";
import wareHouseModule from "./WareHouse";

import itemModule from "./item";
import TeaTypeModule from "./tea-type";
import CategoryModule from "./category";
import markModule from "./Mark";

import seasonsaleModule from "./season-sale";

import documentModule from "./document";

import roleModule from "./Role";

import appModule from "./app-module";
import certificateModule from "./certificate";
import companyWareHouseModule from "./CompanyWareHouse";
import vendorModule from "./vendors";
import userModule from "./user";
import homeModule from "./home";
export const masterModules = {
  home: homeModule,
  role: roleModule,
  state: stateModule,
  masterState: masterStateModule,
  grade: gradeModule,
  district: districtModule,
  wareHouse: wareHouseModule,

  auctionCenter: auctionCenterModule,
  broker: brokerModule,
  competitor: competitorModule,
  season: seasonModule,

  item: itemModule,
  teatype: TeaTypeModule,
  category: CategoryModule,
  mark: markModule,
  certificate: certificateModule,

  seasonsale: seasonsaleModule,
  document: documentModule,
  appModule: appModule,
  companyWareHouse: companyWareHouseModule,
  vendorModule: vendorModule,
  userModule: userModule,
};
