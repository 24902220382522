const Home = () => import("./views/home");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/home",
      component: Home,
      name: "Home",
      meta: {
        groupName: "",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
