const History = () => import("./views/history.vue");
const HistoryDetails = () => import("./views/view-details.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/dispatch_history",
      component: History,
      name: "History",
      meta: {
        groupName: "Delivery Order",
      },
    },
    {
      path: "/dispatch_history_details",
      component: HistoryDetails,
      name: "History-Details",
      meta: {
        groupName: "Delivery Order",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
