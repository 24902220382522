import seasonOfferModule from "./season-offer";
import myBidsModule from "./my-bids";
import privateAllocationModule from "./allocation";
import myAllocationModule from "./my-allocation";
import poGenerationModule from "./po-generation";
import poConfirmationModule from "./POConfirmation";

export const privateModules = {
  seasonOffer: seasonOfferModule,
  privateAllocation: privateAllocationModule,
  myAllocation: myAllocationModule,
  poGeneration: poGenerationModule,
  myBids: myBidsModule,
  poConfirmation: poConfirmationModule,
};
