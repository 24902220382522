const RM_REQUISITION = () => import("./views/rm_requisitions.vue");
const RM_REQUISITIONS_DETAIL = () => import("./views/rm_requisitions_detail.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/rm_requisition",
      component: RM_REQUISITION,
      name: "RM Requisition",
      meta: {
        groupName: "Blend",
      },
    },
    {
      path: "/rm_requisitions_detail",
      component: RM_REQUISITIONS_DETAIL,
      name: "RM Requisition Details",
      meta: {
        groupName: "Blend",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
