const State = () => import("./views/State.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/state",
      component: State,
      name: "State",
      meta: {
        groupName: "Master",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
