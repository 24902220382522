const blendWeighUp = () => import("./views/blend_weigh_up.vue");

import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/blend_sheet_tasting",
      component: blendWeighUp,
      name: "Blend weigh up tasting",
      meta: {
        groupName: "QC & QA",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
