const isProd = process.env.NODE_ENV === "production";

export const apiLink = isProd ? "https://pnbback1.eastea.in/" : "http://localhost:3000/";

export const blendModuleAPI = isProd ? "https://pnbback2.eastea.in/api" : "http://165.22.223.124:8000/api";

export const AWS_URL = isProd
  ? "https://pnb-bucket.s3.ap-south-1.amazonaws.com/"
  : "https://purchase-and-blend-docs-development.s3.ap-south-1.amazonaws.com/";

export const blendModuleVersion = "1";
