import { masterModules } from "./master/module";
import { auctionModules } from "./auction/module";
import { privateModules } from "./private-sale/module";
import { dispatchModules } from "./dispatch/module";
import { internalTranferModules } from "./internal-transfer/module";
import { IrmcModule } from "./irmc/module";
import { grnModules } from "./grn/module";
import { reportModule } from "./Report/module";
import { qcModule } from "./QC/module";
import { purchaseWeighUpModules } from "./purchase-weigh-up-tasting/module";
import { blendModules } from "./blend/module";
import { inventoryModules } from "./inventory/module";
export const modules = Object.assign(
  {},
  masterModules,
  auctionModules,
  privateModules,
  dispatchModules,
  internalTranferModules,
  IrmcModule,
  grnModules,
  purchaseWeighUpModules,
  reportModule,
  qcModule,
  blendModules,
  inventoryModules
);
