const VendorEvaluation = () => import("./views/VendorEvaluationList.vue");
const CreateVendorEvaluation = () =>
  import("./views/CreateVendorEvaluation.vue");
const ViewVendorEvaluation = () =>
  import("./views/ViewVendorEvaluation.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/vendor_evaluation",
      component: VendorEvaluation,
      name: "Vendor Evaluation",
      meta: {
        groupName: "QC & QA",
      },
    },
    {
      path: "/create_vendor_evaluation",
      component: CreateVendorEvaluation,
      name: "New Vendor Evaluation",
      meta: {
        groupName: "QC & QA",
      },
    },
    {
      path: "/create_vendor_evaluation_details",
      component: ViewVendorEvaluation,
      name: "Vendor Evaluation Details",
      meta: {
        groupName: "QC & QA",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
