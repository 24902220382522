const IrmcView = () => import("./views/view-irmc.vue");
const ImrcViewDetails = () => import("./views/view-details.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/irmc",
      component: IrmcView,
      name: "IRMC",
      meta: {
        groupName: "QC & QA",
      },
    },
    {
      path: "/irmc-view",
      component: ImrcViewDetails,
      name: "IRMC-View",
      meta: {
        groupName: "QC & QA",
      },
    },
    ,
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
