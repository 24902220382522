const Document = () => import("./views/Document.vue");
const DocumentSettings = () => import("./views/DocumentSettings.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/document",
      component: Document,
      name: "Document",
      meta: {
        groupName: "Master",
      },
    },
    {
      path: "/document_settings",
      component: DocumentSettings,
      name: "Document Settings",
      meta: {
        groupName: "Master",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
