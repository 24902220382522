const Item = () => import("./views/Item");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/items",
      component: Item,
      name:'Item',
      meta: {
        groupName: "Master",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
