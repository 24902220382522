const MyComments = () => import("./views/my-comments.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/my_comments",
      component: MyComments,
      name: "My Comments",
      meta: {
        groupName: "Auction",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
