const ItdiList = () => import("./views/ItdiList.vue");
const ItdiDetails = () => import("./views/ItdiDetails.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/itdiList",
      component: ItdiList,
      name: "Internal Transfer Dispatch Inspection",
      meta: {
        groupName: "QC & QA",
      },
    },
    {
      path: "/itdiDetails",
      component: ItdiDetails,
      name: "Internal Transfer Dispatch Inspection Details",
      meta: {
        groupName: "QC & QA",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
