import blendCategoryModule from "./category";
import rmRequisitionModule from "./Requisition";
import blendTypeModule from "./type";
import blendCodeModule from "./code";
import blendRecipeModule from "./recipe";
import BlendProvisionalPlanModule from "./provisional-plan";
import BlendSheetModule from "./blendSheet";
import CSTRModule from "./cst";
import MOModule from './mo'

export const blendModules = {
  blendCategory: blendCategoryModule,
  blendPlan: rmRequisitionModule,
  blendType: blendTypeModule,
  blendCode: blendCodeModule,
  blendRecipe: blendRecipeModule,
  blendProvisionalPlan: BlendProvisionalPlanModule,
  blendSheet: BlendSheetModule,
  CSTR: CSTRModule,
  MO:MOModule
};
