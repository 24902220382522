const DeliveryNotes = () => import("./views/delivery-notes.vue");

import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/delivery_notes",
      component: DeliveryNotes,
      name: "Delivery Notes",
      meta: {
        groupName: "Delivery Order",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
