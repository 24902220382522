const BlendCode = () => import("./views/blend_code.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/blend_code",
      component: BlendCode,
      name: "Blend Code",
      meta: {
        groupName: "Blend",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
