const BlendSheets = () => import("./views/blendSheets.vue");
const BlendSheetDetails = () => import("./views/blendSheet-detail.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/blendSheet",
      component: BlendSheets,
      name: "BlendSheets",
      meta: {
        groupName: "Blend",
      },
    },
    {
      path: "/blendSheetDetail",
      component: BlendSheetDetails,
      name: "Blend Sheet Detail",
      meta: {
        groupName: "Blend",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
