const user = () => import("./views/user.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/user",
      component: user,
      name: "User",
      meta: {
        groupName: "Master",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
