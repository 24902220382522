const BuyingTrackingPrivate = () => import("./views/BuyingTrackingPrivate.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/buying_tracking_pvt",
      component: BuyingTrackingPrivate,
      name: "Buying Tracking Report (Private)",
      meta: {
        groupName: "Reports",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
