const InternalDispatch = () => import("./views/internal-dispatch.vue");
const InternalRMArrival = () => import("./views/internal-rm-arrival.vue");
const InternalAutoDispatch = () => import("./views/internal-auto-dispatch.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/internal_dispatch",
      component: InternalDispatch,
      name: "Internal Dispatch",
      meta: {
        groupName: "Transfer",
      },
    },
    {
      path: "/iternal_rm_arrival",
      component: InternalRMArrival,
      name: "Internal RM Arrival",
      meta: {
        groupName: "Transfer",
      },
    },
    {
      path: "/iternal_auto_dispatch",
      component: InternalAutoDispatch,
      name: "Internal Auto Dispatch",
      meta: {
        groupName: "Transfer",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
