const IrmcQuestion = () => import("./views/qustions.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/irmc_question",
      component: IrmcQuestion,
      name: "IrmcQuestions",
      meta: {
        groupName: "IRMC",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
