const BuyingTracking = () => import("./views/BuyingTracking.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/buying_tracking",
      component: BuyingTracking,
      name: "Buying Tracking Report",
      meta: {
        groupName: "Reports",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
