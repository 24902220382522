const MO = () => import("./views/mo.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";
const MODetails = () => import("./views/mo_details.vue");
const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/mo",
      component: MO,
      name: "MO",
      meta: {
        groupName: "Blend",
      },
    },
    {
      path: "/mo_details",
      component: MODetails,
      name: "MODetails",
      meta: {
        groupName: "Blend",
      },
    },
    ,
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
