const History = () => import("./views/history.vue");
const InternalTransferDetail = () =>
  import("./views/internal-transfer-detail.vue");

import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/internal_transfer_history",
      component: History,
      name: "Internal Transfer History",
      meta: {
        groupName: "Transfer",
      },
    },
    {
      path: "/internal_transfer_history",
      component: InternalTransferDetail,
      name: "Internal Transfer Detail",
      meta: {
        groupName: "Transfer",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
