import vendorEvaluationModule from "./VendorEvaluation";
import DensityUploadModule from "./density-upload";
import IndependentWeighUpModule from "./independent-weigh-up";
import BlendWeighUpModule from "./blend-weigh-up";
import ITDIModule from "./ITDI"; //Internal Transfer Dispatch Inspection

export const qcModule = {
  vendorEvaluation: vendorEvaluationModule,
  densityUpload: DensityUploadModule,
  independentWeighUp: IndependentWeighUpModule,
  blendWeighUp: BlendWeighUpModule,
  ITDI: ITDIModule,
};
