const BatchTrack = () => import("./views/batch-track.vue");
const PoDetails = () => import("./views/po-details.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/batch_track",
      component: BatchTrack,
      name: "BatchTrack",
      meta: {
        groupName: "Misc",
      },
    },
    {
      path: "/po_details",
      component: PoDetails,
      name: "PoDetails",
      meta: {
        groupName: "Misc",
      },
    },
    ,
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
