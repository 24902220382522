import Vue from "vue";
// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
import Vuex from "vuex";
import "./api/axios";
import App from "./App.vue";
import DashboardPlugin from "./plugins/dashboard-plugin";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";

import router from "./router";
import store from "./store/index";

import { modules } from "./modules/module";
import { registerModules } from "./register-modules";
import VueTree from "@ssthouse/vue-tree-chart";
registerModules(modules);

Vue.use(Vuex);

Vue.config.productionTip = false;

Vue.use(Photoswipe);
Vue.use(VueSweetalert2);

// plugin setup
Vue.use(DashboardPlugin);
Vue.component("vue-tree", VueTree);
new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");

// createApp(App).use(store).mount('#app')
