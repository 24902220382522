<template>
  <span>
    <template>
      <v-footer color="transparent" class="mt-1 pb-1">
        <v-card class="flex" justify-end color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" class="mx-auto text-center">
                <p class="copyright text-secondary text-md ls-0">
                  Copyright © {{ new Date().getFullYear() }}
                  <a
                    href="https://www.ideenkreisetech.com"
                    class="btn-hover copyright text-secondary text-md ls-0"
                    target="_blank"
                    >Ideenkreise Tech Pvt. Ltd.</a
                  >
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </span>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
};
</script>
