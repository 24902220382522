const DensityUpload = () => import("./views/desnsity-upload.vue");
const NewDensity = () => import("./views/new-density.vue");
import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/density_upload",
      component: DensityUpload,
      name: "DensityUpload",
      meta: {
        groupName: "QC & QA",
      },
    },
    {
      path: "/new_density",
      component: NewDensity,
      name: "NewDensity",
      meta: {
        groupName: "QC",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
