const RMUpload = () => import("./views/rm-upload.vue");

import DashboardLayout from "../../../views/Layout/DashboardLayout.vue";

const moduleRoute = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/rm_upload",
      component: RMUpload,
      name: "RMUpload",
      meta: {
        groupName: "Dispatch",
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
