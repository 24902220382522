import purchaseReportModule from "./PurchaseReport";
import buyingTrackingModule from "./BuyingTracking";
import buyingTrackingPvtModule from "./BuyingTracking Private";
import miscellaneousModule from "./miscellaneous";
import purchaseRequestModule from "./purchase-request";
import poStockModule from "./po-stock";

export const reportModule = {
  purchaseReport: purchaseReportModule,
  poStock: poStockModule,
  buyingTracking: buyingTrackingModule,
  miscellaneous: miscellaneousModule,
  buying_tracking_pvt: buyingTrackingPvtModule,
  purchaseRequestModule: purchaseRequestModule
};
