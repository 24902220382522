import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";

import AuthBasicLayout from "../views/Layout/AuthBasicLayout";

import store from "../store";

const SignUpBasic = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Basic.vue"
  );

// const Datatables = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Applications/Datatables.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: AuthBasicLayout,
    name: "Authentication Basic",
    redirect: "/authentication/sign-in",
    children: [
      {
        path: "/authentication/sign-in",
        name: "SignUpBasic",
        component: SignUpBasic,
      },
    ],
  },

  // authBasicPages,
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  router.app.$store.dispatch("addRoutingPermissions");
  store.commit("SET_ROUTE", to);
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (localStorage.getItem("token_pnb") === null) {
      next({
        name: "SignUpBasic",
      });
    } else {
      next();
    }
  } else {
    next();
  }

  // if (to.name === "SignUpBasic") {
  //   next();
  // } else {
  //   if (localStorage.getItem("token_pnb") === null) {
  //    console.log('inside if');
  //     next({
  //       name: "SignUpBasic",
  //     });
  //   } else {
  //     console.log('inside redirect');
  //     router.app.$store.dispatch("addRoutingPermissions");
  //     store.commit("SET_ROUTE", to);
  //     if (to.matched.some((record) => record.meta.requireAuth)) {
  //       next();
  //     }
  //   }
  // }

  // if (localStorage.getItem("token_pnb") != null && to.name != "SignUpBasic") {
  //   console.log("inside redirect");
  //   router.app.$store.dispatch("addRoutingPermissions");
  //   store.commit("SET_ROUTE", to);
  //   if (to.matched.some((record) => record.meta.requireAuth)) {
  //     next();
  //   }
  // } else {
  //   console.log("inside login");
  //   next();
  // }
});

export default router;
